<template>
  <div>
    <div>
      <span
        class="error--text text-uppercase font-weight-bold text--darken-1 justify-center mb-2"
      >
        <v-icon class="mr-2 mt-n1" color="error">mdi-shopping</v-icon
        >Published Tenders
      </span>
      <p class="grey--text mt-">List of all published tenders</p>
    </div>

    <v-divider></v-divider>
    <v-tabs v-model="tabs" fixed-tab color="error">
      <v-tabs-slider></v-tabs-slider>
      <v-divider vertical></v-divider>
      <v-tab
        href="#mobile-tabs-5-1"
        class="error--text text--darken-1"
        active-class="primary lighten-5"
      >
        <v-icon class="error--text text--darken-1">mdi-shopping</v-icon>
        Marketplace
      </v-tab>
      <v-divider vertical></v-divider>
      <v-tab
        href="#mobile-tabs-5-2"
        class="error--text text--darken-1"
        active-class="primary lighten-5"
      >
        <v-icon class="error--text text--darken-1">mdi-briefcase-check</v-icon>
        My tender alerts
      </v-tab>
      <v-divider vertical></v-divider>
    </v-tabs>
    <v-divider></v-divider>

    <v-tabs-items v-model="tabs">
      <v-tab-item value="mobile-tabs-5-1">
        <v-card flat>
          <v-data-table
            :headers="headers"
            :items="items"
            sort-by="name"
            class="elevation-1"
            :search="search"
            :custom-filter="filterOnlyCapsText"
          >
            <template v-slot:top>
              <v-toolbar flat class="pt-2">
                <v-col cols="12" sm="6" class="mt-5">
                  <!-- <v-toolbar-title
                    class="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
                  >
                    <v-icon class="mr-2 mt-n1" color="error"
                      >mdi-shopping</v-icon
                    >List of Tenders
                  </v-toolbar-title> -->
                  <p class="grey--text ml-n2 mt-n2">
                    All published tenders on marketplace
                  </p>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-col>

                <v-col cols="12" sm="3" class="mt-5">
                  <v-text-field
                    label="Search"
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    small
                    outlined
                    color="primary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="mt-5">
                  <v-select
                    :items="tenderCategories"
                    label="Select Category"
                    item-text="title"
                    item-value="id"
                    small
                    dense
                    persistent-hint
                    return-object
                    single-line
                    color="primary"
                    outlined
                  >
                  </v-select>
                </v-col>
              </v-toolbar>
              <v-progress-linear
                indeterminate
                color="primary"
                v-if="loading"
              ></v-progress-linear>
              <v-divider></v-divider>
            </template>
            <template v-slot:[`item.closing_date`]="{ item }">
              {{ item.closing_date }}
            </template>
            <template v-slot:[`item.documents`]="{ item }">
              {{ item.name }}
              <a
                v-for="(item, i) in item.documents"
                :key="i"
                link
                v-bind:href="docURI"
                >({{ i + 1 }}) {{ item.name }} ,
              </a>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <a v-if="item.status.slug == 'open'" class="success--text">
                {{ item.status.name }}
              </a>
              <a v-else class="grey--text">{{ item.status.name }}</a>
            </template>
            <template v-slot:[`item.company`]="{ item }">
              {{ getCompanyName(item.company) }}
            </template>
            <template v-slot:[`item.tender_no`]="{ item }">
              <a
                @click.stop="tenderPreview(item)"
                class="primary--text text--darken-1 text-decoration-underline"
                >{{ item.tender_no }}</a
              >
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-row align="center" justify="space-around">
                <v-btn
                  depressed
                  color="grey"
                  fab
                  small
                  dark
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>

                <v-btn
                  depressed
                  color="error"
                  fab
                  small
                  dark
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-row>
            </template>

            <template v-slot:[`item.clarifications_count`]="{ item }">
              <v-btn
                class="mx-auto"
                :elevation="0"
                dark
                small
                rounded
                color="warning"
                @click="clarificationPreview(item)"
              >
                <span class="font-weight-bold button">
                  {{
                    item.clarifications_answered +
                    "/" +
                    item.clarifications_count
                  }}</span
                >
              </v-btn>

              <!-- <a
          @click="viewClarification"
          class="info--text text--darken-1 text-decoration-underline"
        >
          <v-badge
            color="info"
            :content="item.clarifications_answered + '/' + item.clarifications"
          >
            <v-icon>mdi-message-alert</v-icon> View
          </v-badge>
        </a> -->
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <!-- my alerts  -->
      <v-tab-item value="mobile-tabs-5-2">
        <v-card flat>
          <v-data-table
            :headers="headers"
            :items="items_selected"
            sort-by="name"
            class="elevation-1"
            :search="search"
            :custom-filter="filterOnlyCapsText"
          >
            <template v-slot:top>
              <v-toolbar flat class="pt-2">
                <v-col cols="12" sm="6" class="mt-5">
                  <!-- <v-toolbar-title
                    class="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
                  >
                    <v-icon class="mr-2 mt-n1" color="error"
                      >mdi-shopping</v-icon
                    >List of Tenders
                  </v-toolbar-title>
                  -->
                  <p class="grey--text ml-n2 mt-n2">
                    All published tenders you are interested
                  </p>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-col>

                <v-col cols="12" sm="3" class="mt-5">
                  <v-text-field
                    label="Search"
                    v-model="search"
                    append-icon="mdi-magnify"
                    dense
                    small
                    outlined
                    color="primary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="mt-5">
                  <v-select
                    :items="tenderCategories"
                    label="Select Category"
                    item-text="title"
                    item-value="id"
                    small
                    dense
                    persistent-hint
                    return-object
                    single-line
                    color="primary"
                    outlined
                  >
                  </v-select>
                </v-col>
              </v-toolbar>
              <v-progress-linear
                indeterminate
                color="primary"
                v-if="loading"
              ></v-progress-linear>
              <v-divider></v-divider>
            </template>
            <template v-slot:[`item.closing_date`]="{ item }">
              {{ item.closing_date }}
            </template>
            <template v-slot:[`item.documents`]="{ item }">
              {{ item.name }}
              <a
                v-for="(item, i) in item.documents"
                :key="i"
                link
                v-bind:href="docURI"
                >({{ i + 1 }}) {{ item.name }} ,
              </a>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <a v-if="item.status.slug == 'open'" class="success--text">
                {{ item.status.name }}
              </a>
              <a v-else class="grey--text">{{ item.status.name }}</a>
            </template>
            <template v-slot:[`item.company`]="{ item }">
              {{ getCompanyName(item.company) }}
            </template>
            <template v-slot:[`item.tender_no`]="{ item }">
              <a
                @click.stop="tenderPreview(item)"
                class="primary--text text--darken-1 text-decoration-underline"
                >{{ item.tender_no }}</a
              >
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-row align="center" justify="space-around">
                <v-btn
                  depressed
                  color="grey"
                  fab
                  small
                  dark
                  @click="editItem(item)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>

                <v-btn
                  depressed
                  color="error"
                  fab
                  small
                  dark
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </v-row>
            </template>

            <template v-slot:[`item.clarifications_count`]="{ item }">
              <v-btn
                class="mx-auto"
                :elevation="0"
                dark
                small
                rounded
                color="warning"
                @click="clarificationPreview(item)"
              >
                <span class="font-weight-bold button">
                  {{
                    item.clarifications_answered +
                    "/" +
                    item.clarifications_count
                  }}</span
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-row>
      <v-col cols="12" md="6" sm="6">
        <v-card class="ml-2">
          <v-card-text v-if="this.bar_chart_is_ready">
            <GChart
              :settings="{ packages: ['bar'] }"
              :data="published"
              :options="publishedOptions"
              :createChart="(el, google) => new google.charts.Bar(el)"
              @ready="onChartReady"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-card class="mr-2" v-if="this.pie_chart_is_ready">
          <v-card-text>
            <GChart
              type="PieChart"
              loading="true"
              :data="tenders_by_category"
              :options="poChartOptions"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TenderService from "../../services/tender.service";
import moment from "moment";

export default {
  components: {
    // UpdateProfile,
  },
  mounted() {
    this.tabs = "#mobile-tabs-5-1";
  },
  data: () => ({
    bar_chart_is_ready: false,
    pie_chart_is_ready: false,
    tabs: null,
    name: "MarketPlace",
    loading: true,
    search: "",
    poChartOptions: {
      title: "Published Categories",
      subtitle: "Current published tenders categories",
      is3D: false,
      pieHole: 0.4,
      legend: {
        position: "bottom",
      },
    },
    tenders_by_category: [],
    published: [],
    publishedOptions: {
      chart: {
        title: "Published Tenders",
        subtitle: "Current published tenders on marketplace",
        color: "#76A7FA",
        is3D: true,
        pieHole: 0.4,
      },
    },

    selected: [0],
    items_selected: [],
    files: "",
    docURI:
      "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
    tenderCategories: [],
    remember: 0,
    timeout: 2000,
    dialog: false,
    countries: ["Kenya", "Uganda", "Tanzania", "Rwanda"],
    owners: [1, 2, 3, 4],

    breadcrumps: [
      {
        text: "Dashboard",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Marketplace",
        disabled: false,
        href: "breadcrumbs_link_1",
      },
      {
        text: "Open Tenders",
        disabled: true,
        href: "breadcrumbs_link_2",
      },
    ],
    valid: true,
    passwordRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    nameRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    emailRules: [
      (v) => !!v || "Valid E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    headers: [
      {
        text: "Tender No",
        align: "start",
        filterable: true,
        value: "tender_no",
      },
      {
        text: "Tender Details",
        value: "title",
      },
      {
        text: "Category",
        value: "category.title",
      },
      {
        text: "Closing Date",
        value: "closing_date",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Clarifications",
        value: "clarifications_count",
      },
    ],
    items: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      company: {
        company_name: "",
      },
      email: "",
      phone: "",
    },
    defaultItem: {
      name: "",
      company: {
        company_name: "",
      },
      email: "",
      phone: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getAllTenders();
    this.getAllTendersByCategory();
    this.getAllLikedTenders();
    this.getCategories();
    this.getPublished();
  },

  methods: {
    onChartReady() {},
    filterOnlyCapsText(value, search, item) {
      console.log(item);
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDate(input) {
      return moment(input).format("Do MMMM  YYYY, h:mm:ss a");
    },
    getCompanyName(company) {
      return company.company_name;
    },
    tenderPreview(item) {
      this.$store.dispatch("tender/setSelected", item);
      this.$router.push("/tender-preview/" + item.id);
    },
    clarificationPreview(item) {
      this.$router.push("/clarification/" + item.id);
    },
    getCategories() {
      return TenderService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.tenderCategories = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    getPublished() {
      return TenderService.getPublished().then(
        (response) => {
          if (response.status == 200) {
            this.published = Object.entries(response.data.data);
            this.bar_chart_is_ready = true;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    getAllTendersByCategory() {
      return TenderService.getAllTendersByCategory().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            let obj = response.data.data;
            this.tenders_by_category = Object.entries(obj);
            console.log(this.tenders_by_category);
            this.pie_chart_is_ready = true;
          } else {
            this.tenders_by_category = [];
            console.log(response);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    getAllTenders() {
      return TenderService.getMarketplaceTenders(this.user.id).then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
            this.loading = false;
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    getAllLikedTenders() {
      return TenderService.getAllLikedTenders(this.user.id).then(
        (response) => {
          if (response.status == 200) {
            this.items_selected = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.items.splice(index, 1)) {
        TenderService.deleteUser(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          this.editedItem.company_name = this.editedItem.company.company_name;
          TenderService.updateUser(this.editedItem).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          this.editedItem.company_name = this.editedItem.company.company_name;
          TenderService.storeUser(this.editedItem).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>
